import i18n from "i18next"; 
import LanguageDetector from "i18next-browser-languagedetector"; 
import XHR from "i18next-xhr-backend"; 
 
import translation_ES_AR from "./locales/es_ar.json"; 
import translation_CN_ZH from "./locales/cn_zh.json"; 
 
i18n 
  .use(XHR) 
  .use(LanguageDetector) 
  .init({ 
    debug: false, 
    lng: "es", 
    fallbackLng: "es", 
 
    keySeparator: false, 
    interpolation: { 
      escapeValue: false, 
    }, 
 
    resources: { 
      es: { 
        translations: translation_ES_AR, 
      }, 
      cn: { 
        translations: translation_CN_ZH, 
      }, 
    }, 
    ns: ["translations"], 
    defaultNS: "translations", 
  }); 

export default i18n;