import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import ReactCountryFlag from "react-country-flag"
import { FloatingWhatsApp } from 'react-floating-whatsapp';

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
  AFIPqr,
  MapsPoint
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  // AFIP Form 960 required
  const AFIP_qr = '<a href="http://qr.afip.gob.ar/?qr=JsBscozDAYaZ_QBFLdBDiQ,," target="_F960AFIPInfo"><img src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" style="width: 92px;height: 130px;"></a>';

  // Google Maps point
  const Maps_Point = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.8253108289405!2d-62.240789885262544!3d-38.72181949431553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95eda315669e8747%3A0xb24bef3d41920151!2sGaribaldi%201245%2C%20B8001GWY%20Bah%C3%ADa%20Blanca%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1674339871608!5m2!1ses-419!2sar" width="400" height="220" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';

  // Open Street Map point (for chinese customers)
  const OpenStreet_Point = '<iframe width="400" height="220" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=-62.23971605300904%2C-38.72232221359693%2C-62.23766148090363%2C-38.72122567023398&amp;layer=mapnik&amp;marker=-38.72177394401871%2C-62.23868876695633" style="border: 1px solid black"></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=-38.72177&amp;mlon=-62.23869#map=19/-38.72177/-62.23869">查看大地图</a></small>';

  let map_point = '<div></div>';

  switch (t('MapsResource')) {
    case "google":
      map_point = Maps_Point;
      break;
    case "openstreetmap":
      map_point = OpenStreet_Point;
      break;
  }

  // Copyright properties
   const copyrightProperties = {
    fontSize: 14,
    color: "#000000",
    marginBottom: "0px"
   }

   const copyrightPropertiesReserved = {
    fontSize: 14,
    color: "#000000"
   }

   const copyrightPropertiesRight = {
    fontSize: 14,
    color: "#000000",
    textAlign: 'right' as 'right',
    marginBottom: "0px"
   }

   const copyrightPropertiesRightPowered = {
    fontSize: 14,
    color: "#000000",
    textAlign: 'right' as 'right'
   }

  // List of employees that uses WhatsApp Business
  const whastapp_employees = ['Marcos', 'Marisa', 'Julieta', 'Micaela', 'Agustina']
  
  // Select any
  const employee = Math.floor(Math.random() * 4);

  return (
    <>
      <FloatingWhatsApp
        phoneNumber="5492915022600"
        accountName={whastapp_employees[employee]}
        statusMessage={t('en línea')}
        placeholder={t('Mensaje')}
        chatMessage={t('Hola 👋🏻 En que te puedo ayudar? 😁')}
        avatar="https://cdn.americanbreakfast.com.ar/resources/assets/marysol/twilio_profile_picture.png"
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Encontranos en")}</Language>
              <Para>Garibaldi 1245</Para>
              <Para>Bahía Blanca, B8001GWY</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Ayuda")}</Title>
              <Large to="/comercios" left="true">
                {t("Venta a comercios")}
              </Large>
              <Large left="true" to="/faq">
                {t("Preguntas Frecuentes")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Idioma")}</Label>
              <LanguageSwitchContainer> 
                <LanguageSwitch onClick={() => handleChange("es")}> 
                  <ReactCountryFlag 
                      className="emojiFlag" 
                      countryCode="AR" 
                      style={{ 
                          fontSize: '30px', 
                          lineHeight: '30px', 
                      }} 
                      aria-label="Español (Argentina)" 
                  /> 
                </LanguageSwitch> 
                <LanguageSwitch onClick={() => handleChange("cn")}> 
                  <ReactCountryFlag 
                      className="emojiFlag" 
                      countryCode="CN" 
                      style={{ 
                          fontSize: '30px', 
                          lineHeight: '30px', 
                      }} 
                      aria-label="简体中文" 
                  /> 
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <MapsPoint dangerouslySetInnerHTML={{__html: map_point}}></MapsPoint>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
            <Empty />
              <Title>{t("Nosotros")}</Title>
              <Large left="true" to="/">
              <a href="https://tienda.marysol.com.ar" onClick={() => window.open('https://tienda.marysol.com.ar')} target="_blank">{t("Tienda online")}</a>
              </Large>
              <Large left="true">
                <a href="https://proveedores.americanbreakfast.com.ar" onClick={() => window.open('https://proveedores.americanbreakfast.com.ar')} target="_blank">{t("Portal de proveedores")}</a>
              </Large>
              <Large left="true" to="/etica">
                {t("Código de ética")}
              </Large>
              <Large left="true" to="/empleos">
                {t("Trabajá con nosotros")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
            <Empty />
              <AFIPqr dangerouslySetInnerHTML={{__html: AFIP_qr}}></AFIPqr>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "1rem" }}
          >
            <Col lg={10} md={10} sm={12} xs={12}>
              <p style={copyrightProperties}>Copyright © 2020 - 2023 LINKED BREAD S.R.L - MarySol</p>
              <p style={copyrightPropertiesReserved}>{t('Todos los derechos reservados')}</p>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <p style={copyrightPropertiesRight}>{t('Desarrollado por')} <a href="https://www.americanbreakfast.com.ar" target="_blank">American Breakfast</a></p>
              <p style={copyrightPropertiesRightPowered}>{t('Basado en')} <a href="https://github.com/Adrinlol/landy-react-template" target="_blank">Landy</a></p>
            </Col>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
