import { useState } from "react";
import { BrowserRouter, Route, useLocation } from "react-router-dom";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import Select from 'react-select'
import i18n from "i18next";

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NavLinked,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { isSet } from "util/types";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  // For language switch in menu
  const menu_switch_lang = [
    { value: 'es', label: `${t("Español")} 🇦🇷` },
    { value: 'cn', label: `${t("Chino")} 🇨🇳` }
  ]

  const location = useLocation();

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

    const ContactItem = () => {
      switch (location.pathname) {
        case "/":
          // Home
          return (
            <>
              <CustomNavLinkSmall onClick={() => scrollTo("contact")}>
                <Span>{t("Contacto")}</Span>
              </CustomNavLinkSmall>
            </>
          );
          break;
        default:
          return (
            <>
              <CustomNavLinkSmall>
                <NavLinked to="/#contact"><Span>{t("Contacto")}</Span></NavLinked>
              </CustomNavLinkSmall>
            </>
          );
          break;
      }
    }

    return (
      <>
        <CustomNavLinkSmall>
          <NavLinked to="/"><Span>{t("Inicio")}</Span></NavLinked>
        </CustomNavLinkSmall>
        <ContactItem />
        <CustomNavLinkSmall>
            <Select
              isSearchable={false}
              defaultValue={menu_switch_lang[parseInt(t("LangID"))]}
              options={menu_switch_lang}
              onChange={(e) => {handleChange(e?.value || "es")}}
            />
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => window.open('https://comercios.americanbreakfast.com.ar')}
        >
          <Span>
            <Button>{t("Ingreso a comercios")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <img src="https://cdn.americanbreakfast.com.ar/resources/assets/marysol/marysol_logo.png" width="150px" height="70px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>{t('Menú')}</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
