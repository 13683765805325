const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/comercios", "/enterpises"],
    exact: true,
    component: "Enterprises",
  },
  {
    path: ["/faq"],
    exact: true,
    component: "Faq",
  },
  {
    path: ["/etica", "/ethics"],
    exact: true,
    component: "Ethics",
  },
  {
    path: ["/empleos", "/jobs"],
    exact: true,
    component: "Jobs",
  },
];

export default routes;
